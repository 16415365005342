import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { msalConfig } from "../config";

export function LoginButton() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { t } = useTranslation();
  const Navigate = useNavigate();

  const handleLogin = () => {
    instance.loginRedirect({...msalConfig.scopes.authRequest, prompt: "select_account"});

    if (location.pathname === "/login") {
      Navigate("/");
    }
  };

  return (
    <>
      {!isAuthenticated && (
        <Button data-cy={"loginButton"} onClick={handleLogin}>
          {t("Login")}
        </Button>
      )}
    </>
  );
}
