import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import async from "./components/Async";
// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
import {
  AllRoles,
  AllRolesExcluding,
  AuthGuard,
  MatchType,
  Role,
  RoleGuard,
} from "./components/Guards";

////Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// import DocLayout from "./layouts/Doc";
import PresentationLayout from "./layouts/Presentation";
////Auth components
import Page404 from "./pages/auth/Page404";
import Notifications from "./pages/dashboard/Core/Notifications/Index";
// import Page500 from "./pages/auth/Page500";

////Presentation components
import Login from "./pages/presentation/LoginPage";

////Dashboard components
// const DashboardIndex = async(() => import("./pages/dashboard/Index"));
//Projects
const ProjectProjectIndex = async(() =>
  import("./pages/dashboard/Projects/Index")
);
const ProjectProjectCreate = async(() =>
  import("./pages/dashboard/Projects/Project/Create/Index")
);
const ProjectProjectDetail = async(() =>
  import("./pages/dashboard/Projects/Project/Detail/Index")
);
const ProjectsActivitySchedule = async(() =>
  import("./pages/dashboard/Projects/Scheduling/Index")
);
const ProjectAllocate = async(() =>
  import("./pages/dashboard/Projects/Allocate/Index")
);

//Type
const ProjectTypeList = async(() =>
  import("./pages/dashboard/Projects/Type/Index")
);
//Stage
const ProjectStageList = async(() =>
  import("./pages/dashboard/Projects/Stage/Index")
);
//Status
const ProjectStatusList = async(() =>
  import("./pages/dashboard/Projects/Status/Index")
);

//Finance
const FinanceListQuotation = async(() =>
  import("./pages/dashboard/Finance/Quotations/Index")
);
//Finance
const FinanceDetailsQuotation = async(() =>
  import("./pages/dashboard/Finance/Quotations/Details/Details")
);

const FinanceEditQuotation = async(() =>
  import("./pages/dashboard/Finance/Quotations/Quotation/Index")
);

const FinanceCurrencies = async(() =>
  import("./pages/dashboard/Finance/Currency/Index")
);

const FinanceTeamPricingGroups = async(() =>
  import("./pages/dashboard/Finance/TeamPricingGroup/Index")
);

const FinanceCountryPricingGroups = async(() =>
  import("./pages/dashboard/Finance/CountryPricingGroup/Index")
);

const FinanceInvoicingSchedule = async(() =>
  import("./pages/dashboard/Finance/ProjectInvoicingSchedule/Index")
);

const FinanceInvoicingScheduleCreate = async(() =>
  import("./pages/dashboard/Finance/ProjectInvoicingSchedule/Create")
);

const FinanceInvoicingScheduleEdit = async(() =>
  import("./pages/dashboard/Finance/ProjectInvoicingSchedule/Edit")
);

const FinanceExpensesEdit = async(() =>
  import("./pages/dashboard/Finance/Expenses/Edit/Index")
);

const FinanceExpensesUpload = async(() =>
  import("./pages/dashboard/Finance/Expenses/Upload/Index")
);

const FinanceExpensesExchangeRates = async(() =>
  import("./pages/dashboard/Finance/Expenses/ExchangeRate/Index")
);

const FinanceReportsExpenses = async(() =>
  import("./pages/dashboard/Finance/Reports/Expenses/Index")
);

const FinanceReportsNordicExpenses = async(() => import("./pages/dashboard/Finance/Reports/Nordic/Index"))

const FinanceReportsAnalysis = async(() =>
  import("./pages/dashboard/Finance/Reports/Analysis/Index")
);

const FinanceGlobalInvoiceItems = async(() =>
  import("./pages/dashboard/Finance/GlobalInvoicingSchedule/Index")
);

const FinanceOdometerReadings = async(() =>
  import("./pages/dashboard/Finance/OdometerReadings/Index")
);

//Planning
const PlanningCalendar = async(() =>
  import("./pages/dashboard/Planning/Calendar/Calendar")
);

const DealerProfile = async(() =>
  import("./pages/dashboard/Planning/DealerProfile/DealerProfileIndex")
);

const EventSearch = async(() =>
  import("./pages/dashboard/Planning/Event/Search")
);

const PlanningEventDetail = async(() =>
  import("./pages/dashboard/Planning/Event/Detail")
);

const PlanningCustomEventDetail = async(() =>
  import("./pages/dashboard/Planning/CustomEvent/Details/Detail")
);

const ToDo = async(() => import("./pages/dashboard/Planning/ToDo/Index"));

const PlanningActivityList = async(() =>
  import("./pages/dashboard/Planning/Reports/ActivityList")
);

const PlanningTeamList = async(() =>
  import("./pages/dashboard/Planning/Reports/TeamList")
);

const PlanningAllowancesReport = async(() =>
  import("./pages/dashboard/Planning/Reports/AllowancesReport")
);

const PlanningPlanningProductivity = async(() =>
  import("./pages/dashboard/Planning/Reports/PlanningProductivity")
);

const PlanningCustomerDaysProductivity = async(() =>
  import("./pages/dashboard/Planning/Reports/CustomerDaysProductivity")
);

const PlanningQuarterlyActivitySummary = async(() =>
  import("./pages/dashboard/Planning/Reports/QuarterlyActivitySummary")
);

const PlanningQuarterlyDaysSummary = async(() =>
  import("./pages/dashboard/Planning/Reports/QuarterlyDaysSummary")
);

const PlanningDaysList = async(() =>
  import("./pages/dashboard/Planning/Reports/DaysList")
);

const StaffDays = async(() =>
  import("./pages/dashboard/Planning/Reports/StaffDays")
);

//External planning
const PlanningEventPlan = async(() =>
  import("./pages/auth/external/Plan/Plan")
);

//Scheduling
const PersonSchedulingActivitySchedule = async(() =>
  import("./pages/dashboard/Planning/Schedule/Index")
);

//Operations
const OperationsDealerLocations = async(() =>
  import("./pages/dashboard/Operations/DealerLocations")
);
const OperationsDeviationStatusOverview = async(() => import("./pages/dashboard/Operations/DeviationStatusOverview"))

//ECS
const ECSAboutUs = async(() => import("./pages/dashboard/ECS/AboutUs/Index"));
const ECSDocuments = async(() => import("./pages/dashboard/ECS/Documents"));
const ECSTemplates = async(() => import("./pages/dashboard/ECS/Templates"));
const ECSContacts = async(() => import("./pages/dashboard/ECS/Contacts/Index"));
const ECSApplications = async(() =>
  import("./pages/dashboard/ECS/Applications")
);

const ECSTerms = async(() => import("./pages/dashboard/ECS/Terms"));

//Profile
const ProfileUserProfile = async(() =>
  import("./pages/dashboard/Profile/Profile/Index")
);
const ProfileSettings = async(() =>
  import("./pages/dashboard/Profile/Settings/Settings")
);

//Customer profile
const CustomerProfileIndex = async(() =>
  import("./pages/dashboard/CustomerProfile/Index")
);

//Core
const CoreCustomersList = async(() =>
  import("./pages/dashboard/Core/Customers/Index")
);

const DealerImportList = async(() =>
  import("./pages/dashboard/Core/DealerImports/Index")
);

const DealerLocation = async(() =>
  import("./pages/dashboard/Core/DealerImports/Location")
);

const FillUnplannedDays = async(() =>
  import("./pages/dashboard/Core/FillUnplannedDays/Index")
);

const AuditLogsIndex = async(() =>
  import("./pages/dashboard/Core/AuditLog/Index")
);
const AuditLogDetail = async(() =>
  import("./pages/dashboard/Core/AuditLog/Detail")
);

//Feedback
const FeedbackAdminFeedback = async(() =>
  import("./pages/dashboard/Feedback/AdminFeedback")
);
const FeedbackEditFeedback = async(() =>
  import("./pages/dashboard/Feedback/EditFeedback")
);

//Admin
const AdminViewPurchaseOrders = async(() =>
  import("./pages/dashboard/Core/PurchaseOrders/Index")
);
const AdminViewEventApproval = async(() =>
  import("./pages/dashboard/Core/EventApproval/Index")
);

//Resourcing
const ResourcingPersonTotalDays = async(() =>
  import("./pages/dashboard/Resourcing/PersonTotalDays")
);

const ResourcingPersonMonthlyCount = async(() =>
  import("./pages/dashboard/Resourcing/PersonMonthlyCount")
);

const ResourcingProjectMonthlyCount = async(() =>
  import("./pages/dashboard/Resourcing/ProjectMonthlyCount")
);

const ResourcingActivityMontlyCount = async(() =>
  import("./pages/dashboard/Resourcing/ActivityMonthlyCount")
);

const IndexRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => navigate("/planning"));

  return null;
};

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <IndexRedirect />,
      },
      {
        path: "planning",
        element: (
          <RoleGuard matchType={MatchType.Single} roles={AllRoles} isPage />
        ),
        children: [
          {
            index: true,
            element: <PlanningCalendar />,
          },
          {
            path: "dealerProfile",
            element: <DealerProfile />,
          },
          {
            path: "dealerProfile/:dealerId",
            element: <DealerProfile />,
          },
          {
            path: "event",
            children: [
              {
                path: ":eventId",
                element: <PlanningEventDetail />,
              },
            ],
          },
          {
            path: "eventsearch",
            element: <EventSearch />,
          },
          {
            path: "customevent",
            children: [
              {
                path: ":eventId",
                element: <PlanningCustomEventDetail />,
              },
            ],
          },
          {
            path: "schedule",
            element: <PersonSchedulingActivitySchedule />,
          },
          {
            path: "ToDo",
            element: <ToDo />,
          },
          {
            path: "reports",
            children: [
              {
                path: "ActivityList",
                element: <PlanningActivityList />,
              },
              {
                path: "TeamList",
                element: <PlanningTeamList />,
              },
              {
                path: "AllowancesReport",
                element: <PlanningAllowancesReport />,
              },
              {
                path: "PlanningProductivity",
                element: <PlanningPlanningProductivity />,
              },
              {
                path: "CustomerDaysProductivity",
                element: <PlanningCustomerDaysProductivity />,
              },
              {
                path: "QuarterlyActivitySummary",
                element: <PlanningQuarterlyActivitySummary />,
              },
              {
                path: "QuarterlyDaysSummary",
                element: <PlanningQuarterlyDaysSummary />,
              },
              {
                path: "DaysList",
                element: <PlanningDaysList />,
              },
              {
                path: "StaffDays",
                element: (
                  <RoleGuard
                    roles={AllRolesExcluding(Role.Specialist)}
                    matchType={MatchType.Single}
                    isPage
                  >
                    <StaffDays />
                  </RoleGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "projects",
        element: (
          <RoleGuard
            roles={[Role.Finance, Role.Projects]}
            matchType={MatchType.Single}
            isPage
          />
        ),
        children: [
          {
            index: true,
            element: <ProjectProjectIndex />,
          },
          {
            path: ":projectId",
            children: [
              {
                index: true,
                element: <ProjectProjectDetail />,
              },
              {
                path: "activityschedules",
                element: <ProjectsActivitySchedule />,
              },
              {
                path: "allocate",
                element: <ProjectAllocate />,
              },
            ],
          },
          {
            path: "create",
            element: <ProjectProjectCreate />,
          },
          {
            path: "types",
            element: (
              <RoleGuard matchType={MatchType.Single} isPage>
                <ProjectTypeList />
              </RoleGuard>
            ),
          },
          {
            path: "stages",
            element: (
              <RoleGuard matchType={MatchType.Single} isPage>
                <ProjectStageList />
              </RoleGuard>
            ),
          },
          {
            path: "status",
            element: (
              <RoleGuard matchType={MatchType.Single} isPage>
                <ProjectStatusList />
              </RoleGuard>
            ),
          },
        ],
      },
      {
        path: "finance",
        element: (
          <RoleGuard
            roles={[Role.Finance]}
            matchType={MatchType.Single}
            isPage
          />
        ),
        children: [
          {
            path: "invoiceschedule",
            element: <FinanceGlobalInvoiceItems />,
          },
          {
            path: ":projectId/quotations",
            children: [
              {
                index: true,
                element: <FinanceListQuotation />,
              },
              {
                path: "create",
                element: <FinanceEditQuotation />,
              },
              {
                path: ":quotationId/edit",
                element: <FinanceEditQuotation />,
              },
              {
                path: ":quotationId/preview",
                element: <FinanceDetailsQuotation />,
              },
            ],
          },
          {
            path: ":projectId/invoicing",
            children: [
              {
                index: true,
                element: <FinanceInvoicingSchedule />,
              },
              {
                path: "create",
                element: <FinanceInvoicingScheduleCreate />,
              },
              {
                path: ":invoiceScheduleId/edit",
                element: <FinanceInvoicingScheduleEdit />,
              },
            ],
          },
          {
            path: "currencies",
            element: <FinanceCurrencies />,
          },
          {
            path: "teampricinggroups",
            element: <FinanceTeamPricingGroups />,
          },
          {
            path: "countrypricinggroups",
            element: <FinanceCountryPricingGroups />,
          },
          {
            path: "expenses",
            children: [
              {
                path: "exchangerates",
                element: <FinanceExpensesExchangeRates />,
              },
              {
                path: "upload",
                element: <FinanceExpensesUpload />,
              },
              {
                path: "edit",
                element: <FinanceExpensesEdit />,
              },
            ],
          },
          {
            path: "reports",
            children: [
              {
                path: "expense",
                element: <FinanceReportsExpenses />,
              },
              {
                path: "analysis",
                element: <FinanceReportsAnalysis />,
              },
            ],
          },
          {
            path: "odometerreadings",
            element: <FinanceOdometerReadings />,
          },
        ],
      },
      {
        path: "Core",
        element: <RoleGuard matchType={[Role.Single]} isPage />,
        children: [
          {
            path: "customers",
            element: <CoreCustomersList />,
          },
          {
            path: "dealerimports",
            element: <DealerImportList />,
          },
          {
            path: "dealerlocations",
            element: <DealerLocation />,
          },
          {
            path: "auditlogs",
            element: <AuditLogsIndex />,
          },
          {
            path: "auditlogs/details/:auditLogId",
            element: <AuditLogDetail />,
          },
        ],
      },
      {
        path: "Notifications",
        element: (
          <RoleGuard matchType={MatchType.Single} roles={AllRoles} isPage />
        ),
        children: [
          {
            index: true,
            element: <Notifications isMe={true} />,
          },
        ],
      },
      {
        path: "ECS",
        element: (
          <RoleGuard matchType={MatchType.Single} roles={AllRoles} isPage />
        ),
        children: [
          {
            path: "apps",
            element: <ECSApplications />,
          },
          {
            path: "contacts",
            element: <ECSContacts />,
          },
          {
            path: "about-us",
            element: <ECSAboutUs />,
          },
          {
            path: "terms",
            element: <ECSTerms />,
          },
          {
            path: "group-policies",
            element: <ECSDocuments />,
          },
          {
            path: "templates",
            element: <ECSTemplates />,
          },
        ],
      },
      {
        path: "profile",
        element: (
          <RoleGuard matchType={MatchType.Single} roles={AllRoles} isPage />
        ),
        children: [
          {
            index: true,
            element: <ProfileUserProfile isMe={true} />,
          },
          {
            path: ":usersAzureObjectId",
            element: <ProfileUserProfile isMe={false} />,
          },
          {
            path: "settings",
            element: <ProfileSettings />,
          },
        ],
      },
      {
        path: "customerprofile",
        element: (
          <RoleGuard
            roles={[Role.Finance, Role.Projects]}
            matchType={MatchType.Single}
            isPage
          />
        ),
        children: [
          {
            path: ":customerId",
            element: <CustomerProfileIndex />,
          },
        ],
      },
      {
        path: "Resourcing",
        matchType: MatchType.Single,
        roles: [Role.Finance],
        children: [
          {
            path: "PersonTotalDays",
            element: <ResourcingPersonTotalDays />,
          },
          {
            path: "PersonMonthlyCount",
            element: <ResourcingPersonMonthlyCount />,
          },
          {
            path: "ProjectMonthlyCount",
            element: <ResourcingProjectMonthlyCount />,
          },
          {
            path: "ActivityMonthlyCount",
            element: <ResourcingActivityMontlyCount />,
          },
        ],
      },
      {
        path: "admin",
        element: (
          <RoleGuard
            matchType={MatchType.Single}
            roles={AllRolesExcluding(Role.Specialist)}
            isPage
          />
        ),
        children: [
          {
            path: "purchaseorders",
            element: <AdminViewPurchaseOrders />,
          },
          {
            path: "activityapprovals",
            element: <AdminViewEventApproval />,
          },
          {
            path: "fillunplanneddays",
            element: (
              <RoleGuard
                matchType={MatchType.Single}
                roles={[Role.GroupAdmin]}
                isPage
                children={<FillUnplannedDays />}
              />
            ),
          },
          {
            path: "nordicExpense",
            element: (
              <RoleGuard 
                matchType={MatchType.Single} 
                roles={[Role.GroupAdmin]} 
                isPage 
                children={<FinanceReportsNordicExpenses />} 
              />
            ),
          },
        ],
      },
      {
        path: "operations/dealerlocations",
        element: (
          <RoleGuard
            matchType={MatchType.Single}
            roles={[Role.Operations, Role.Finance]}
            isPage
            children={<OperationsDealerLocations />}
          />
        ),
      },
      {
        path: "operations/deviationstatusoverview",
        element: (
          <RoleGuard
            matchType={MatchType.Single}
            roles={[Role.Operations, Role.VWGReports]}
            isPage
            children={<OperationsDeviationStatusOverview />}
          />
        ),
      },
      {
        path: "feedback",
        element: <RoleGuard matchType={MatchType.Single} isPage />,
        children: [
          {
            index: true,
            element: <FeedbackAdminFeedback />,
          },
          {
            path: ":id",
            element: <FeedbackEditFeedback />,
          },
        ],
      },
    ],
  },
  {
    path: "external",
    element: (
      <AuthGuard
        children={<RoleGuard matchType={MatchType.Single} roles={AllRoles} />}
      />
    ),
    children: [
      {
        path: "plan/:projectId/:customerId/:personId/:activityTypeId/:dealerId",
        element: (
          <AuthLayout disableOutlet>
            <PlanningEventPlan />
          </AuthLayout>
        ),
      },
    ],
  },
  {
    path: "login",
    element: (
      <PresentationLayout>
        <Login />
      </PresentationLayout>
    ),
  },
  {
    //Usually goes at the end as a catch
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
