import { AccountInfo } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
//import { useNavigate } from "react-router-dom";
import Auth from "../layouts/Auth";
import Page401 from "../pages/auth/Page401";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import Presentation from "../layouts/Presentation";
import LoginPage from "../pages/presentation/LoginPage";
import { allNullOrUndefined, isNullOrUndefined } from "../utils/generalHelpers";
import { useHasAuthorization } from "../hooks/TypeScript/useHasAuthorization";

export function AuthGuard(props: { children: JSX.Element | JSX.Element[] }) {
  const { children } = props;

  const getChildren = useMemo(() => {
    if (Array.isArray(children)) return children.map((x) => x);
    return children;
  }, [children]);

  return (
    <>
      <AuthenticatedTemplate>
        {getChildren ? getChildren : <Outlet />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Presentation noOutlet={true}>
          <LoginPage />
        </Presentation>
      </UnauthenticatedTemplate>
    </>
  );
}

export enum MatchType {
  Single = 0,
  All = 1,
}

export enum Role {
  Admin = "Admin",
  Finance = "Finance",
  GroupAdmin = "GroupAdmin",
  Projects = "Projects",
  Operations = "Operations",
  RegionalManger = "RegionalManager",
  Specialist = "Specialist",
  Sales = "Sales",
  VWGReports = "VWGReports",
}

export const AllRoles: Role[] = [
  Role.Admin,
  Role.Finance,
  Role.GroupAdmin,
  Role.Projects,
  Role.Operations,
  Role.RegionalManger,
  Role.Specialist,
  Role.Sales,
];

export const AllRolesExcluding = (rolesToExclude: Role | Role[]) => {
  if (Array.isArray(rolesToExclude)) {
    return AllRoles.filter((x) => !rolesToExclude.includes(x));
  } else {
    return AllRoles.filter((x) => x !== rolesToExclude);
  }
};

/**
 *
 * @param props.roles Roles.Admin is always included - even if undefined
 * @param props.filterType Needs implementing
 * @returns
 */
export function RoleGuard(props: {
  children: JSX.Element | JSX.Element[];
  matchType?: MatchType | undefined;
  roles?: Role[];
  isPage?: boolean | undefined;
  ignoreAdminOverride?: boolean;
}) {
  const { children, matchType, isPage, roles, ignoreAdminOverride } = props;
  const userHasAuth = useHasAuthorization({
    roles: !isNullOrUndefined(roles)
      ? [...(roles as Role[]), Role.Admin]
      : [Role.Admin],
    matchType: matchType ?? MatchType.Single,
    ignoreAdminOverride: ignoreAdminOverride,
  });

  const getChildren = useMemo(() => {
    if (Array.isArray(children)) return <>{children.map((x) => x)}</>;
    return children;
  }, [children]);

  if (userHasAuth) {
    if (!getChildren) {
      return <Outlet />;
    } else {
      if (Array.isArray(children)) return <>{children.map((x) => x)}</>;
      return children;
    }
  }

  if (isPage) {
    return (
      <Auth>
        <Page401 />
      </Auth>
    );
  } else {
    return null;
  }
}

/**
 *
 * @param userAccount React-Msal AccountInfo
 * @param matchType Decides how to match the roles i.e. single or all
 * @param propRoles Roles to match
 * @param ignoreAdminOverride Stops Admin always being true
 * @returns True if the Account has Authorization else false
 */
export function AccountInfoHasAuthorization(
  userAccount: AccountInfo | null,
  matchType?: MatchType,
  propRoles?: Role[],
  ignoreAdminOverride?: boolean
) {
  const usersRoles = (userAccount as any)?.idTokenClaims?.roles ?? undefined;
  if (isNullOrUndefined(matchType)) return true;
  if (allNullOrUndefined([matchType, propRoles])) return true;
  if (allNullOrUndefined([usersRoles, matchType, propRoles])) return true;
  if (!(ignoreAdminOverride ?? false) && usersRoles?.includes(Role.Admin)) {
    return true;
  }

  if (
    matchType === MatchType.Single &&
    propRoles?.some((role) => usersRoles?.includes(role))
  )
    return true;

  if (
    matchType === MatchType.All &&
    propRoles?.every((role) => usersRoles?.includes(role))
  )
    return true;

  return false;
}
